function findStatus(statuses, statusId) {
  return _.find(statuses, function(s) {
    return s.id == statusId;
  });
}

angular.module('LeasePilot').directive('statusPicker', [
  'DocumentService',
  '$rootScope',
  '$mdDialog',
  'leaseExportService',
  'LeaseEditorService',
  function(
    DocumentService,
    $rootScope,
    $mdDialog,
    leaseExportService,
    LeaseEditorService
  ) {
    return {
      restrict: 'E',
      templateUrl: 'status_picker.html',
      scope: {
        id: '@',
        status: '=',
        disabled: '=',
        previousStatus: '=',
        type: '@',
        tenant: '@',
        statuses: '=',
        company: '@',
        exportStatus: '@',
      },
      link: function($scope) {
        if ($scope.status)
          $scope.status = findStatus($scope.statuses, $scope.status.id);
      },
      controller: [
        '$scope',
        function StatusPickerController($scope) {
          function handleExportSuccess(task) {
            var editorScope = angular.element($('.lease-editor')).scope();
            if (editorScope) {
              editorScope.loadDocument(task.lease_id, $scope.type);
            }

            if (task.status === 'exported') {
              $mdDialog.cancel();

              if ($scope.status.wait_for_ack) {
                $rootScope.$emit('leaseExportStatusUpdated', "wait_for_ack", null);
                $scope.safeApply();
                handleOpenExternalUrl();
              } else {
                $rootScope.$emit('leaseExportStatusUpdated', null, null); // reset pending status
                // update status
                new DocumentService({
                  id: $scope.id,
                  statusId: $scope.status.id,
                  type: $scope.type,
                })
                  .update()
                  .then(function() {
                    $scope.applyChangeLockLease();
                    handleOpenExternalUrl();
                  });
              }
            } else {
              // if export fails try to fetch lease or reload a page
              if (editorScope) {
                // if user is on editor page
                const toStatus = _.find(window.statuses, s => s.id === task.to_status);

                editorScope.loadDocument(task.lease_id, $scope.type);
                $mdDialog.show({
                  template:
                    '<md-dialog><show-export-errors-modal ' +
                    "error-type='" +
                    task.status +
                    "' " +
                    "target-status='" +
                    toStatus.description +
                    "' " +
                    "errors='" +
                    JSON.stringify(task.task_errors) +
                    "'>" +
                    '</show-export-errors-modal></md-dialog>',
                  parent: angular.element(document.body),
                  clickOutsideToClose: true,
                  fullscreen: false,
                });
                
                $scope.$emit('leaseStatusReset', null);
              } else {
                // if user is on leases index page
                window.location.reload();
              }
            }
          }

          function handleExportError(error) {
            if (error === 'ExportNotEnabled') {
              new DocumentService({
                id: $scope.id,
                statusId: $scope.status.id,
                type: $scope.type,
              })
                .update()
                .then(function() {
                  $rootScope.$emit(
                    'leaseExportStatusUpdated',
                    null,
                    null,
                  );
                  $scope.applyChangeLockLease();
                });
            } else {
              $scope.$emit('leaseStatusReset', null);
              $scope.applyChangeLockLease();
            }
          }

          function handleOpenExternalUrl() {
            if ($scope.status.open_external_url) {
              window.open($scope.status.open_external_url);
            }
          }

          $scope.update = function(_, oldStatus) {
            $scope.previousStatus = oldStatus;

            if (window.lease) {
              window.lease_previous_status = window.lease.status;
              window.lease.status = $scope.status;
              window.lease_previous_status_id = window.lease.statusId;
              window.lease.statusId = $scope.status.id;
            }
            
            if ($scope.status.endpoint) {
              if (!$scope.status.export) {
                $scope.exportStatus = 'pending';
                
                leaseExportService
                  .changeStatus($scope.id, handleExportSuccess)
                  .catch(handleExportError);
              } else {
                $scope.exportStatus = 'pending';
  
                leaseExportService
                  .exportLease($scope.id, $scope.type, null, handleExportSuccess)
                  .catch(handleExportError);
              }
            } else {
              var params = {
                id: $scope.id,
                statusId: $scope.status.id,
                type: $scope.type,
              };

              window.track.event(new ChangeStatusEvent({
                status: $scope.status.description,
              }));

              new DocumentService(params).update().then(function() {
                $scope.applyChangeLockLease();
                handleOpenExternalUrl();
              });
            }
          };

          $rootScope.$on('leaseExportStatusUpdated', function(
            event,
            exportStatus,
            statusId,
          ) {
            $scope.exportStatus = exportStatus;
            if (statusId) {
              $scope.status = findStatus($scope.statuses, statusId);
              window.lease_previous_status = null;
              window.lease.status = $scope.status;
              window.lease_previous_status_id = null;
              window.lease.statusId = $scope.status.id;
              
              $rootScope.$emit('leaseStatusChanged', {
                lock: $scope.disabled || $scope.status.lock,
              });
            }
          });

          $scope.$on('leaseStatusReset', function(event, exportStatus) {
            $scope.exportStatus = exportStatus;
            $scope.status = findStatus($scope.statuses, $scope.previousStatus);
            $rootScope.$emit('leaseStatusChanged', {
              lock: $scope.disabled || $scope.status.lock,
            });

            window.lease_previous_status = null;
            window.lease.status = $scope.status;
            window.lease_previous_status_id = null;
            window.lease.statusId = $scope.status.id;
          });

          $scope.selectStatus = function(statusId) {
            $rootScope.safeApply(() => {
              $scope.status = findStatus($scope.statuses, statusId);
              $scope.update(null, statusId);
            });
          };

          $scope.applyChangeLockLease = function() {
            $rootScope.$emit('leaseStatusChanged', {
              lock: $scope.disabled || $scope.status.lock,
            });
          };
        },
      ],
    };
  },
]);
